var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "20px" },
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.addOrUpdateHandle()
            }
          }
        },
        [_vm._v("新增")]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.dataList, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "type",
              "header-align": "center",
              width: "100",
              align: "center",
              label: "医嘱类型"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "contents",
              "header-align": "center",
              align: "center",
              label: "医嘱内容"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "",
              "header-align": "center",
              align: "center",
              label: "启用状态"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949",
                        "active-value": true,
                        "inactive-value": false,
                        "active-text": scope.row.status ? "已启用" : "已禁用"
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeHandle(scope.row.id, $event)
                        }
                      },
                      model: {
                        value: scope.row.status,
                        callback: function($$v) {
                          _vm.$set(scope.row, "status", $$v)
                        },
                        expression: "scope.row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              width: "200",
              label: "操作"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.addOrUpdateHandle(scope.row)
                          }
                        }
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.deleteHandle(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.dialogTitle, visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.formData, "label-width": "140px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "医嘱类型" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.formData.type,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "type", $$v)
                        },
                        expression: "formData.type"
                      }
                    },
                    _vm._l(_vm.types, function(item) {
                      return _c("el-option", {
                        key: item.type,
                        attrs: { label: item.type, value: item.type }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "自定义医嘱类型" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "自定义医嘱类型", clearable: "" },
                    model: {
                      value: _vm.formData.customType,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "customType", $$v)
                      },
                      expression: "formData.customType"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "医嘱内容" } },
                [
                  _c("el-input", {
                    attrs: {
                      rows: 5,
                      placeholder: "示例： 忌发物|忌辛辣|忌生冷",
                      type: "textarea",
                      clearable: ""
                    },
                    model: {
                      value: _vm.formData.contents,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "contents", $$v)
                      },
                      expression: "formData.contents"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "医嘱启用状态" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.formData.status,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "status", $$v)
                      },
                      expression: "formData.status"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.submit } },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }