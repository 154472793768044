<template>
	<div>
		<el-button type="primary" @click="addOrUpdateHandle()" style="margin-bottom: 20px;">新增</el-button>
		<el-table :data="dataList" border style="width: 100%;">
			<el-table-column prop="type" header-align="center" width="100" align="center" label="医嘱类型"></el-table-column>
			<el-table-column prop="contents" header-align="center" align="center" label="医嘱内容"></el-table-column>
			<el-table-column prop="" header-align="center" align="center" label="启用状态">
				<template slot-scope="scope">
					<el-switch
						v-model="scope.row.status"
						@change="changeHandle(scope.row.id, $event)"
						active-color="#13ce66"
						inactive-color="#ff4949"
						:active-value="true"
						:inactive-value="false"
						:active-text="scope.row.status ? '已启用' : '已禁用'"
					></el-switch>
					<!-- <span style="margin-left: 10px;">{{scop.row.status ? '已启用' : '未启用'}}</span> -->
				</template>
			</el-table-column>
			<el-table-column header-align="center" align="center" width="200" label="操作">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="addOrUpdateHandle(scope.row)">修改</el-button>
					<el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible">
			<el-form :model="formData" label-width="140px">
				<el-form-item label="医嘱类型">
					<el-select v-model="formData.type">
						<el-option v-for="item in types" :key="item.type" :label="item.type" :value="item.type"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="自定义医嘱类型">
					<el-input v-model="formData.customType" placeholder="自定义医嘱类型" clearable></el-input>
				</el-form-item>
				<el-form-item label="医嘱内容">
					<el-input v-model="formData.contents" :rows="5" placeholder="示例： 忌发物|忌辛辣|忌生冷" type="textarea" clearable></el-input>
				</el-form-item>
				<el-form-item label="医嘱启用状态">
					<el-switch
						v-model="formData.status"
					></el-switch>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submit">提交</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>
<script>

export default {
	name: 'doctorsadvice',
	data () {
		return {
			dialogTitle: '新增医嘱',
			dialogFormVisible: false,
			dataList: [],
			types: [],
			formData: {
				id: '',
				type: '',
				customType: '',
				contents: '',
				status: true
			}
		};
	},
	created () {
		this.search();
		this.typeList();
	},
	methods: {
		search () {
			this.$root.request('doctorsadviceList', {}).then((data) => {
				if (data) {
					this.dataList = data;
				}
			});
		},
		typeList () {
			this.$root.request('doctorsadviceTypelist', {}).then((data) => {
				if (data) {
					this.types = data;
				}
			});
		},
		addOrUpdateHandle (row) {
			this.dialogFormVisible = true;
			if (row) {
				this.dialogTitle = '编辑医嘱';
				this.formData = { ...row };
			} else {
				this.dialogTitle = '新增医嘱';
				this.formData = {
					id: '',
					type: '',
					customType: '',
					contents: '',
					status: true
				};
			}
		},
		changeHandle (id, status) {
			this.$root.request('doctorsadviceStatusChange', {
				id, status
			}).then((data) => {
				if (data && data.ok === 1) {
					this.$root.elMsg('操作成功');
					this.search();
				}
			});
		},
		submit () {
			this.$root.request('doctorsadviceOpt', this.formData).then((data) => {
				if (data && data.ok === 1) {
					this.$root.elMsg('操作成功');
					this.dialogFormVisible = false;
					this.search();
				}
			});
		}
	}
};
</script>